import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const SponsorSection = () => {
  const data = useStaticQuery(graphql`
    {
      SWAContent {
        sponsors {
          imgURL
          link
          name
        }
      }
    }
  `);

  const [sponsors, setSponsors] = useState(data.SWAContent.sponsors);

  return (
    <section className="relative max-w-4xl pb-10 mx-auto sm:w-4/5">
      <h2 className="text-swa-1">Sponsors</h2>
      <p className="text-xl leading-8 prose prose-lg text-gray-500">
        We are grateful to our long time sponsors for contributing to our Annual
        Charity Dinner fundraising event.
      </p>

      <ul
        role="list"
        className="grid content-center justify-center grid-cols-2 p-3 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
      >
        {sponsors.map((sponsor, i) => (
          <div key={i} className="relative">
            <a
              href={`https://${sponsor.link}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="block bg-white group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-swa-3 ">
                <img
                  src={sponsor.imgURL}
                  alt={sponsor.name}
                  className="object-contain p-2 transition duration-500 border border-gray-200 pointer-events-none opacity-70 group-hover:opacity-100 group-hover:border-swa-3"
                />
              </div>
              <p className="block text-sm font-light text-center truncate pointer-events-none text-swa-3">
                {sponsor.name}
              </p>
            </a>
          </div>
        ))}
      </ul>
    </section>
  );
};

export default SponsorSection;
