import React from "react";
import { Link } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Clamp from "react-multiline-clamp";

import ReactHtmlParser from "react-html-parser";

const FeaturedCard = ({ event }) => {
  // console.log("featured card event", event);

  return (
    <Link to={`/events/${event.slug}`}>
      <div className="flex flex-col p-4 my-5 transition duration-300 bg-white rounded-lg shadow-lg cursor-pointer opacity-80 md:items-start hover:bg-gray-100 hover:opacity-100">
        <h3 className="block w-full text-swa-3">{event.eventTitle}</h3>
        <div className="flex flex-col md:w-full md:justify-between gap-y-3 md:flex-row md:gap-x-3">
          <div className="md:w-1/3">
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={event.eventPhoto}
                alt={event.eventTitle}
                className="object-cover w-full h-full rounded"
              />
            </div>
          </div>
          <div className="md:w-2/3">
            <Clamp lines={4}>
              <div className="mt-2 text-lg ">
                {ReactHtmlParser(event.eventSummary)}
              </div>
            </Clamp>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default FeaturedCard;
