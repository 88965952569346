import * as React from "react";
import SEO from "../components/seo";

import NoticeSection from "../components/sections/home/NoticeSection";
import HeroSection from "../components/sections/home/HeroSection";
import SponsorSection from "../components/sections/home/SponsorSection";
import FeaturedSection from "../components/sections/home/FeaturedSection";
// import ProgrammeSection from "../components/sections/home/ProgrammeSection";
import ParticipateSection from "../components/sections/home/ParticipateSection";

import Layout from "../components/layout/layout";
import Seo from "../components/seo";

const IndexPage = () => (
  <>
    {/* --- background pattern --- */}
    <div className="absolute w-full h-full bg-bottom bg-no-repeat bg-cover -top-60 lg:top-0 z-n10 bg-hero-pattern-2 "></div>
    {/* <div className="absolute w-full h-full bg-bottom bg-no-repeat bg-cover z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div> */}
    <Layout>
      <SEO title="Singapore Women's Association" />

      <HeroSection />
      <NoticeSection />
      <FeaturedSection />
      {/* <ProgrammeSection /> */}
      <ParticipateSection />
      <SponsorSection />
      <div className="absolute bottom-0 w-11/12 h-48 py-24 bg-no-repeat bg-cover z-n10 bg-sponsor-pattern"></div>
    </Layout>
  </>
);

export default IndexPage;

// deploy
