import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// import { GatsbyImage, getImage } from "gatsby-plugin-image"

import FeaturedCard from "./FeaturedCard";

const FeaturedSection = () => {
  const data = useStaticQuery(graphql`
    {
      SWAContent {
        events {
          eventDate
          eventTitle
          eventSummary
          eventPhoto
          slug
          featured
          event {
            title
            paragraph
            image
            caption
            type
          }
        }
      }
    }
  `);

  const [featuredEvents, setFeaturedEvents] = useState(data.SWAContent.events);
  // console.log("featuredevents: ", featuredEvents);

  const sortedEvents = featuredEvents.sort(function (a, b) {
    return new Date(b.eventDate) - new Date(a.eventDate);
  });

  // console.log("sorted: ", sortedEvents);

  return (
    <section className="relative max-w-4xl mx-auto sm:w-4/5">
      {/* --- background pattern ---  */}
      {/* <div className="absolute w-full h-full p-5 bg-red-200 bg-repeat-y bg-cover md:bg-contain bg-featured-pattern z-n10"></div> */}

      <div className="sm:w-3/5">
        <h2 className="text-swa-1">Highlights</h2>
        <p className="text-xl leading-8 prose prose-lg text-gray-500">
          Our events and activities focus on contributing towards three key
          pillars. They are Elderly, Community and Diversity.
        </p>

        <Link to="/events/" className="inline-block mt-2 mb-5 btn-dark md:mb-0">
          View all events
        </Link>
      </div>

      <div className="space-y-10 md:ml-auto sm:max-w-2xl">
        {sortedEvents &&
          sortedEvents.map((eventItem, i) => {
            if (eventItem.featured === true) {
              return <FeaturedCard event={eventItem} key={i} />;
            } else return null;
          })}
        {/* {featuredEvents.map(event => (
          <FeaturedCard event={event} key={event.id} />
        ))} */}
      </div>
    </section>
  );
};

export default FeaturedSection;
