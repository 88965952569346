import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const ParticipateSection = () => {
  return (
    <section className="max-w-4xl mx-auto sm:w-4/5">
      <div className="sm:w-3/5">
        <h2 className="text-swa-1">Get involved</h2>
        <p className="text-xl leading-8 prose prose-lg text-gray-500">
          Give a helping hand to those who need it!
        </p>
      </div>

      <div className="mx-auto sm:max-w-2xl">
        <div className="p-6 my-5 bg-gray-100 sm:flex rounded-xl">
          <div className="flex flex-col justify-start w-full mb-2 sm:mr-2 sm:w-1/2">
            <StaticImage
              src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636988711/participatePage/b6pxktbcremk0woujiqk.jpg"
              alt=""
              className="object-cover max-h-full border-4 border-white"
            />
            {/* <span className="text-xs text-gray-500">
              Photo by https://www.cjtay.com
            </span> */}
          </div>
          <div className="w-full sm:w-1/2">
            <h3 className="text-swa-1">Donate</h3>
            <p className="text-lg leading-normal">
              SWA welcomes donations and support from individuals and
              corporations. Your kind gesture will help us improve the lives of
              our beneficiaries and contribute to our charity efforts to enhance
              the welfare of the underprivileged.
            </p>
            <Link to="/participate/donate">
              <div className="flex justify-start mt-2">
                <button className="inline-block btn-dark">Donate</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="p-6 my-5 bg-gray-100 sm:flex sm:flex-row-reverse rounded-xl">
          <div className="flex flex-col justify-start w-full mb-2 sm:ml-2 sm:w-1/2">
            <StaticImage
              src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636789348/participatePage/gpjfmamd6zynjemg6cue.jpg"
              alt=""
              className="object-cover max-h-full border-4 border-white"
            />
            {/* <span className="text-xs text-gray-500">
              Photo by https://unsplash.com/@jemsahagun
            </span> */}
          </div>

          <div className="w-full sm:w-1/2">
            <h3 className="text-swa-1 sm:text-right">Volunteer</h3>
            <p className="text-lg leading-normal sm:text-right">
              SWA welcomes volunteers for supporting our charity efforts. You
              can be part of us and contribute your time or expertise to help us
              with administrative matters and management of our charity
              programmes &amp; projects.
            </p>
            <Link to="/participate/volunteer">
              <div className="flex justify-end mt-2 ">
                <button className=" btn-dark">Volunteer</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ParticipateSection;
