import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import ReactHtmlParser from "react-html-parser";

import { BellIcon } from "@heroicons/react/outline";

const AnnouncementSection = () => {
  const data = useStaticQuery(graphql`
    {
      SWAContent {
        notice {
          title
          notice
          link
          status
        }
      }
    }
  `);
  const [notice, setNotice] = useState(data.SWAContent.notice[0]);

  return (
    <>
      {notice.notice !== "" && notice.status === "approved" && (
        <section>
          <div className="relative px-5 py-4 mx-auto bg-white border md:w-9/12 xl:w-6/12 rounded-xl ">
            <div className="absolute inset-0 rounded-lg bg-swa-1 filter blur animate-pulse z-n10 bg-gradient-to-r from-pink-400 to-swa-3"></div>
            <BellIcon className="absolute w-10 h-10 p-2 rounded-full text-swa-1 -left-5 -top-5 bg-swa-4 animate-pulse" />

            <h2 className="mb-2 text-center text-swa-1">{notice.title}</h2>
            <div className="text-lg text-center text-gray-500 md:leading-8 ">
              {ReactHtmlParser(notice.notice)}
            </div>
            {notice.link && (
              <div className="flex justify-center mt-3">
                <Link to={notice.link}>
                  <button className="btn-dark">More</button>
                </Link>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default AnnouncementSection;
